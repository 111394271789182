<template>
  <div class="page page-xadmin-products">
    <PageHeader
    :icon="$t('SERVICE.ICON')"
    :title="$t('SERVICE.TITLES')"
    back="/settings/"
    >
      <template slot="nav">
        <small><strong>{{$tc('SERVICE.TITLE_COUNT',count)}}</strong></small>
      </template>

    </PageHeader>

    <Loading :value="loading" />

    <div class=" min-height-100 scroll">
      <v-row class="ma-10">
        <template v-for="(item, i) in services">
          <template v-if="item.spacer">
            <v-col cols="12" :key="'serv'+i"></v-col>
          </template>
          <template v-else-if="item.divider">
            <v-col cols="12" :key="'serv'+i"><v-divider  /></v-col>
          </template>
          <template v-else>
            <v-col cols="4" :key="'serv'+i">
              <template v-if="i === 1">
                <v-card flat height="100%">
                  <h4 class="pa-4 text-nowrap text-ellipsis">
                    <v-icon
                      color="success"
                      :class="['mr-2']"
                    >
                      mdi-circle
                    </v-icon>
                    {{ item.title }}
                  </h4>
                  <v-divider />
                  <div class="pa-4">
                    <span class="font-weight-bold">version: </span>
                    <span class="text-decoration-underline font-weight-bold numeric">{{ item.data }}</span>
                  </div>
              </v-card>
              </template>
              <template v-else>
                <v-card flat>
                <h4 class="pa-4 text-nowrap text-ellipsis">
                  <v-icon
                    :color="item.data.alive ? 'success' : 'error'"
                    :class="['mr-2']"
                  >
                    mdi-circle
                  </v-icon>
                  {{ item.title }}
                </h4>
                <v-divider />
                <div class="pa-4">
                  <template v-for="(d , j) in Object.keys(item.data)">
                    <div :key="'s'+i+j">
                      <small>
                        <span class="grey--text">{{ d }} </span>
                        <span class="numeric">{{ item.data[d] }}</span>
                      </small>
                    </div>
                  </template>
                </div>
                <v-divider />
                <div class="pa-2 d-flex">
                  <v-btn
                    depressed
                    x-small
                    outlined
                    @click="checkService(item.name)"
                  >
                    <v-icon size="14">mdi-sync</v-icon>
                    refresh
                  </v-btn>
                  <v-spacer />
                  <a
                    target="_blank"
                    :href="getHomeLink(item.name)"
                  >
                    <v-btn depressed x-small outlined>
                      <v-icon size="14">mdi-book</v-icon>
                      doc
                    </v-btn>
                  </a>
                </div>
              </v-card>
              </template>         
            </v-col>
          </template>
        </template>
      </v-row>
    </div>

  </div>
</template>

<script>
// TODO:
// Finish backend and review page

import Loading from '@/components/Loading'
import DialogRemove from '@/components/DialogRemove'
import Side from '@/components/Side'
import List from '@/components/List'
import PageHeader from '@/components/PageHeader'
import projectVersion from '@/utils/getProjectVersion'

export default {
  name: 'PageSettingsXadminProducts',
  components: {
    Loading,
    List,
    PageHeader,
  },
  data: () => ({
    loading: false,
    items: [],
    count: 7,
    offset: 0,
    limit: 100,
    page: 1,
    selected: [],
    showEdit: false,
    showDialogRemove: false,
    showDialogPermissionEdit: false,
    timeout: null,
    status: [],
    methods: [],
    sindex: {
      ui_client: 1,
      api_programmatic: 0,
      api_worker_feed: 2,
      api_worker_publication: 3,
      api_worker_job: 4,
      api_user: 6,
      api_rnd: 7,
      api_statistic: 8,
    },
    services: [
      { name: "api_programmatic", title: "api_programmatic", data: {}, status: undefined },
      { name: "ui_client", title: "ui_client", data: projectVersion },
      { spacer: true },
      { name: "api_worker_feed", title: "api_programmatic_worker_feed", data: {}, status: undefined },
      { name: "api_worker_publication", title: "api_programmatic_worker_publication", data: {}, status: undefined },
      { name: "api_worker_job", title: "api_programmatic_worker_job", data: {}, status: undefined },
      { divider: true },
      { name: "api_user", title: "api_user", data: {}, status: undefined },
      { name: "api_rnd", title: "api_rnd", data: {}, status: undefined },
      { name: "api_statistic", title: "api_statistic", data: {}, status: undefined },
    ],
  }),

  computed: {
    paramsId () {
      return this.$router.currentRoute.params.id || undefined
    },

    itemId () {
      return this.$router.currentRoute.query.edit || undefined
    },
  },

  mounted () {
    this.$queryFilters().get()

    this.checkServices()
  },

  methods: {

    getHomeLink (name) {
      if (name === 'ui_client') return
      const i = this.sindex[name]
      return this.$services[name] ? this.$services[name]._baseURL + '/docs' : 'about:blank'
    },
    checkService (name) {
      if (name === 'ui_client') return
      const i = this.sindex[name]
      const item = this.services[i]
      this.loading = true
      this.$services[name].ping()
      .then(response => {
        if (response.data) {
          this.services[i].data = response.data || {}
        } else {
          this.services[i].data = {}
        }
      })
      .catch(e => {
        this.services[i].data = {error: e.stack}
      })
      .finally(() => {
        this.loading = false
      })

    },

    // Request

    checkServices () {
      for (const key of Object.keys(this.sindex)) this.checkService(key)
    },

    changePage (page) {
      this.page = +page
      this.searchItems()
    },

  }
}
</script>
